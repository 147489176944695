<ng-template #customBrand>
    <div class="px-4">
        <a *ngIf="!checkCurrentInvestorId()" class="pointer" data-cy="GCMHeaderLogo">
            <img class="inline-block h-6 lg:h-10" src="{{evService.assetsFolderPath + '/images/GCM-logo-blue.svg'}}"
                alt="GCM" />
            <img class="inline-block clientScopeLogo h-6 lg:h-10 object-contain"
                src="{{evService.assetsFolderPath + '/images/Client-scope-logo.svg'}}" alt="Client Scope" />
        </a>
        <a *ngIf="checkCurrentInvestorId()" class="pointer inline-block" routerLinkActive="active"
            [routerLink]="['/investor', selectedInvestorId]" [queryParams]="getDashboardQueryParams()" (click)="setActiveInvestor()"
            data-cy="GCMHeaderLogo">
            <img class="inline-block h-6 lg:h-10" src="{{evService.assetsFolderPath + '/images/GCM-logo-blue.svg'}}"
                alt="GCM" />
            <img class="inline-block clientScopeLogo h-6 lg:h-10 object-contain"
                src="{{evService.assetsFolderPath + '/images/Client-scope-logo.svg'}}" alt="Client Scope" />
        </a>
    </div>
</ng-template>

<ng-template #customHeaderContent>
    <div class="flex items-center justify-between flex-wrap">
        <div class="tour-icon" title="Click this icon to activate guided dashboard tour"
            (click)="tourService.start(); activityTracking.tourServiceStarted(true)">
        </div>
    </div>
</ng-template>

<ng-template #customProfile>
    <app-investor-dropdown data-cy="investorDropdown" class="hidden lg:block mb-1.5"></app-investor-dropdown>
    <button data-cy="userDropdown" mat-icon-button [matMenuTriggerFor]="accountMenu" aria-label="account" class="navbar__account-btn">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu" MenuPositionX="after" [overlapTrigger]="false" MenuPositionY="below">
        <div class="navbar__username pl-2">
            <small class="navbar__username__greeting">Welcome,</small>
            {{ displayName }}
        </div>
        <a mat-menu-item href="https://myapps.microsoft.com/" target="_blank" *ngIf="!userService.isExternalUser()">
            <mat-icon>apps</mat-icon>
            <span>My Apps</span>
        </a>
        <button mat-menu-item data-cy="logoutButton" (click)="logoutClick()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</ng-template>
<gcm-header [branding]="customBrand" [content]="customHeaderContent" [profile]="customProfile"></gcm-header>
