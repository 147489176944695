<div class="main-content mt-16">
    <div *ngIf="iconSet" id="clientScopeApp" class="px-4 pb-3 pt-1">
        <div *ngIf="hasError; else showDetails">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 dashboard-error">
                            <div>
                                <h3 class="text-center">{{errorMessage}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #showDetails>
            <div class="row mh-42 mx-0">
                <div class="col-12 px-0">
                    <div class="lg:mt-4 bread-crumb-container grid lg:col-12 lg:flex mr-3.5 lg:mx-0">
                        <div class="col-12 px-0 lg:contents">
                            <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                                <a class="custom-breadcrumb__text" attr.data-cy="breadcrumb{{breadcrumb.type}}"
                                    routerLinkActive="active" [routerLink]="breadcrumb.route"
                                    [queryParams]="breadcrumbDataService.getQueryParamsForBreadcrumbs(breadcrumb)"
                                    [title]="breadcrumb.name" (click)="breadcrumbClick(breadcrumb)">
                                    {{breadcrumb.name}}</a>
                                <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
                            </div>
                        </div>
                        <app-auto-adjusting-dropdown [selected]="assetName"
                            [dataForBreadcrumbs]="assetsDataForBreadcrumb" (goToSelected)="goToSelectedAsset($event)"
                            [queryParams]="queryAssetMasterId" (sendWidth)="getWidth($event)" [valueProperty]="'id'"
                            [nameProperty]="'name'" [type]="'Assets'"
                            data-cy="assetDropdown"></app-auto-adjusting-dropdown>
                    </div>
                </div>
            </div>
            <!-- NOTE: Hide reporting period dropdown for now, and will add 
                back in when we have asset type specific time series data -->
            <!-- <div class="row mh-42">
                <div class="col-12">
                    <div class="flex">
                        <div class="asofDates flex ml-auto">
                            <div>
                                <img src="{{iconSet['icon.calendar']}}" class="asofDates__icon" />
                            </div>
                            <select name="asOfDateddl" class="asofDates__as-custom-select" id="asOfDateSel"
                                [(ngModel)]="selectedAsOfDate" (change)='updateAsOfDate($event.target.value)'>
                                <option *ngFor="let date of asOfDropdownData" [value]="date">
                                    {{date | dateFormat : 'MM/dd/yyyy'}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="showAssetNotFound; else showAssetDetails">
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 dashboard-error">
                            <div>
                                <h3 class="text-center">Asset Not Found</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #showAssetDetails>
                <div class="row mh-61 mx-0 mt-4 lg:mt-0 grid grid-cols-1 md:grid-cols-3 lg:px-0">
                    <div class="md:col-span-2 lg:px-0">
                        <div class="align-middle">
                            <div class="asset-title" data-cy="assetTitle">{{assetName}}</div>
                            <span *ngIf="assetSummary.showAssetUrl" class="text-md md:text-lg"><a
                                    href="{{assetSummary.assetURL}}" target="_blank"
                                    (click)="activityTracking.navigateToAssetUrl('asset dashboard', queryAssetMasterId, assetName, assetSummary.assetURL)">{{assetSummary.assetURL}}</a></span>
                        </div>
                    </div>
                    <div class="md:col-span-1" *ngIf="!isAssetDataUnavailable">
                        <div class="snap-shot mr-3 flex justify-end align-middle">
                            <button (click)="getSnapshot();" class="my-0 md:my-2.5 flex border-primary"
                                data-cy="snapshotButton"><img src="{{iconSet['icon.snapshot']}}"
                                    class="mr-2 mt-1" />Snapshot</button>
                        </div>
                    </div>
                </div>

                <app-error-card *ngIf="isAssetDataUnavailable" data-cy="errorCard">
                    <h3 class="card-title">Asset Data is not available for this Asset.</h3>
                    <div>
                        <div>Please return to the prior page or Click
                            <a routerLinkActive="active" [routerLink]="breadcrumbs[0]?.route"
                                (click)="breadcrumbClick(breadcrumbs[0])"
                                [queryParams]="breadcrumbDataService.getQueryParamsForBreadcrumbs(breadcrumbs[0])"
                                class="click-here-btn" data-cy="investorDashboardRedirect">
                                here
                            </a>
                            to go back to the Investor dashboard.
                        </div>
                    </div>
                </app-error-card>

                <app-asset-summary [assetType]="assetType" [assetData]="assetData" [title]="assetName"
                    [summary]="assetSummary.summary" [logo]="assetSummary.logo"
                    [slidingImgs]="assetSummary.slidingImgs"></app-asset-summary>
                <app-financial-summary [assetType]="assetType" [iconSet]="iconSet" [selectedAsOfDate]="selectedAsOfDate"
                    [financialData]="assetData"></app-financial-summary>
                <ng-container *ngIf="assetType === 'Real Estate' && assetSummary.showMap">
                    <app-map-widget [position]="assetSummary.position"></app-map-widget>
                </ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>