import { Component, OnInit } from '@angular/core';
import { SearchEngineService } from '../services/search-engine.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ContentfulService } from '../services/contentful.service';
import { ContentfulConfigService } from '../services/contentful-config.service';
import { BreadcrumbDataService } from '../services/breadcrumb-data.service';
import { InvestorPortfolioSeriesReportingHoldingAssetBreadcrumb } from '../models/classes/investor-portfolio-series-reporting-holding-asset-breadcrumb.class';
import { ApiService } from '../core/http/api.service';
import { AssetDashboard } from '../models/classes/asset-dashboard.class';
import { UserService } from '../services/user.service';
import { SnapshotService } from '../services/snapshot.service';
import { AssetMeta } from '../models/classes/asset-meta.class';
import { LoggingService } from '../services/logging.service';
import { NgTourService } from '../services/ng-tour-ui.service';
import { NgTourStepsService } from '../services/ng-tour-steps.service';
import { AssetSummaryContentful } from '../models/classes/asset-summary-contentful.class';
import { InvestorRepository } from '../services/repositories/investor.repository';
import { ActivityTrackingService } from '../services/activity-tracking.service';
import { CommonModule } from '@angular/common';
import { NoAccessErrorComponent } from '../shared/no-access-error/no-access-error.component';
import { MatIconModule } from '@angular/material/icon';
import { AutoAdjustingDropdownComponent } from '../auto-adjusting-dropdown/auto-adjusting-dropdown.component';
import { ErrorCardComponent } from '../shared/error-card/error-card.component';
import { AssetSummaryComponent } from './asset-summary/asset-summary.component';
import { FinancialSummaryComponent } from './financial-summary/financial-summary.component';
import { MapWidgetComponent } from '../shared/map-widget/map-widget.component';
import { GcmLoadingService } from '@gcm/gcm-ui-angular';
import { DateFormatPipe } from '../pipes/date-format.pipe';

@Component({
  selector: 'app-asset-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    NoAccessErrorComponent,
    MatIconModule,
    AutoAdjustingDropdownComponent,
    ErrorCardComponent,
    RouterModule,
    AssetSummaryComponent,
    FinancialSummaryComponent,
    MapWidgetComponent
  ],
  templateUrl: './asset-dashboard.component.html',
  styleUrls: ['./asset-dashboard.component.scss']
})
export class AssetDashboardComponent implements OnInit {
  iconSet: {};
  params = {};
  queryParamsInvestorId: string;
  queryParamsSeriesId: number;
  queryParamsHoldingId: number;
  queryAssetMasterId: number;
  valReportingDate: string = '';
  hasError: boolean = false;
  errorMessage: string = '';
  parametersErrorMessage: string = '';
  breadcrumbs: any[] = [];
  assetsDataForBreadcrumb: InvestorPortfolioSeriesReportingHoldingAssetBreadcrumb[] = [];
  assetName: string = '';
  asOfDropdownData: string[] = [];
  selectedAsOfDate: string = '';
  assetType: string = '';
  financialData: Record<string, string | number> = {};
  assetData: Record<string, string>;
  showNoAccessError: boolean = false;
  assetSummary: AssetSummaryContentful = new AssetSummaryContentful();
  showAssetNotFound: boolean = false;
  firstTimeLoadingAssets: boolean = true;
  isAssetDataUnavailable: boolean = false;
  isDashboardApiError: boolean = false;

  constructor(
    private seoService: SearchEngineService,
    private activeRoute: ActivatedRoute,
    private contentfulService: ContentfulService,
    private contentfulConfig: ContentfulConfigService,
    public breadcrumbDataService: BreadcrumbDataService,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private snapService: SnapshotService,
    private loadingService: GcmLoadingService,
    private loggingService: LoggingService,
    private ngTourSteps: NgTourStepsService,
    private ngTourUi: NgTourService,
    private investorRepository: InvestorRepository,
    public activityTracking: ActivityTrackingService,
    private dateFormat: DateFormatPipe
  ) {
    this.ngTourSteps.getContentfulDataFetchedValue().subscribe((data: boolean) => {
      if (data) this.ngTourUi.setTourSteps(ngTourSteps.assetDashboardSteps);
    })
  }

  ngOnInit(): void {
    this.showNoAccessError = false;
    const queryParams = this.userService.queryParams = this.activeRoute.snapshot.queryParams;
    this.params = this.userService.params = this.activeRoute.snapshot.params;
    this.setFilters(queryParams, this.params);

    this.contentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });
  }

  setFilters(queryParams, params) {
    this.seoService.setCanonicalURL();
    this.queryParamsInvestorId = params['investorId'] ?? '';
    this.queryParamsSeriesId = params['seriesId'] ?? '';
    this.queryParamsHoldingId = params['holdingId'];
    this.queryAssetMasterId = params['assetId'];
    this.valReportingDate = queryParams['reportingdate'];
    this.checkIfUserIsExternal(this.queryParamsInvestorId);
    this.fetchCommonAssets();
  }

  checkIfUserIsExternal(queryParamsInvestorId) {
    if (this.userService.isExternalUser()) {
      this.checkExternalUserAccess(queryParamsInvestorId);
    } else {
      this.getAssetDashboardDetails();
    }
  }

  checkExternalUserAccess(queryParamsInvestorId: any) {
    const assignedInvestorIds = this.userService.getInvestorIds();
    if (!assignedInvestorIds.includes(parseInt(queryParamsInvestorId))) {
      this.hasError = true;
      this.showNoAccessError = true;
      this.loadingService.setLoading(false);
      return;
    } else {
      this.hasError = false;
      this.getAssetDashboardDetails();
    }
  }

  getAssetDashboardDetails() {
    this.showAssetNotFound = false;
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, this.queryParamsHoldingId, this.queryAssetMasterId, this.valReportingDate).then((data: AssetDashboard) => {
      this.loadingService.setLoading(false);
      this.getBreadcrumbDropdownData(this.params['investorId']);
      this.assetData = data?.summary;
      this.assetType = this.assetData.assetType;
      if (this.assetData.businessDescription && this.assetData.businessDescription != '') {
        this.assetSummary.summary = this.assetData.businessDescription;
      } else {
        this.assetSummary.summary = '';
      }
      if (this.assetData.website && this.assetData.website != '') {
        this.assetSummary.showAssetUrl = true;
        let url = this.assetData.website;
        this.assetSummary.assetURL = !(/(http(s?)):\/\//i.test(url)) ? "https://" + url : url;
      }
      else {
        this.assetSummary.showAssetUrl = false;
      }
    });
  }

  fetchDashboardDataFromApi(seriesId, holdingId, assetId, reportingDate) {
    return new Promise((resolve, reject) => {
      this.apiService.getAssetDashboardDetails(seriesId, holdingId, assetId, reportingDate).subscribe({
        next: (data) => {
          this.isDashboardApiError = false;
          resolve(data);
        },
        error: (err) => {
          if (err?.error?.errors?.length > 0) {
            if (err?.error?.errors[0]?.message?.includes('User does not have access')) {
              this.isDashboardApiError = true;
              this.loadingService.setLoading(true);
              this.getBreadcrumbDropdownData(this.queryParamsInvestorId);
            }
          } else {
            this.hasError = true;
            this.errorMessage = err?.error ?? 'Something went wrong, Please try again!';
          }
          this.loadingService.setLoading(false);
          reject(err);
        }
      });
    })
  }

  fetchCommonAssets() {
    this.contentfulService.fetchCommonAssets('assetSummary', '', this.queryAssetMasterId.toString()).subscribe((res: any) => {
      if (res.data.assetDetailsCollection.items.length === 0) {
        this.assetSummary.logo = '';
        this.assetSummary.slidingImgs = [];
      }
      else {

        if (res.data.assetDetailsCollection.items[0]?.map?.lat === undefined) {
          this.assetSummary.showMap = false;
        }
        else {
          this.assetSummary.showMap = true;
          this.assetSummary.position = {
            lat: res.data.assetDetailsCollection.items[0].map.lat,
            lng: res.data.assetDetailsCollection.items[0].map.lon
          };
        }

        if (!res.data.assetDetailsCollection.items[0].logo) {
          this.assetSummary.logo = '';
        }
        else {
          this.assetSummary.logo = res.data.assetDetailsCollection.items[0].logo.url;
        }

        if (res.data.assetDetailsCollection.items[0].photosCollection.items.length !== 0) {
          this.assetSummary.slidingImgs = [];
          for (let ssind = 0; ssind < res.data.assetDetailsCollection.items[0].photosCollection.items.length; ssind++) {
            this.assetSummary.slidingImgs[ssind] = { src: res.data.assetDetailsCollection.items[0].photosCollection.items[ssind].url };
          }
        } else {
          this.assetSummary.slidingImgs = [];
        }
      }
    });
  }

  getBreadcrumbDropdownData(queryParamsInvestorId: string) {
    if (this.queryParamsSeriesId && queryParamsInvestorId !== '') {
      this.loadingService.setLoading(true);
      this.apiService.getAssetDashboardMetadata(this.queryParamsSeriesId, this.queryParamsHoldingId, this.queryAssetMasterId).subscribe({
        next: (data: AssetMeta) => {
          this.breadcrumbs = [];
          const dashboardBreadcrumb = this.breadcrumbDataService.getDashboardBreadcrumb(queryParamsInvestorId);
          this.breadcrumbs.push(dashboardBreadcrumb);
          const seriesBreadcrumbs = this.breadcrumbDataService.getPortfolioSeriesBreadcrumbs(queryParamsInvestorId, this.queryParamsSeriesId, data.parent.parent.parent)
          this.breadcrumbs.push(seriesBreadcrumbs);
          const holdingsBreadcrumbs = this.breadcrumbDataService.getHoldingsBreadcrumbs(queryParamsInvestorId, this.queryParamsSeriesId, this.queryParamsHoldingId, data.parent)
          this.breadcrumbs.push(holdingsBreadcrumbs);
          if (this.firstTimeLoadingAssets) {
            this.assetsDataForBreadcrumb = data.relatedEntities;
            this.firstTimeLoadingAssets = false;
          }
          this.assetName = this.breadcrumbDataService.getAssetName(this.assetsDataForBreadcrumb, this.queryAssetMasterId);
          this.isAssetDataUnavailable = this.isDashboardApiError;
          this.loadingService.setLoading(false);
        },
        error: (err) => {
          this.loggingService.log("----- Asset Dashboard Metadata API Exception -----", err);
          this.loadingService.setLoading(false);
        }
      });
    }
  }

  goToSelectedAsset(assetId) {
    let selectedAsset = new InvestorPortfolioSeriesReportingHoldingAssetBreadcrumb();
    this.assetsDataForBreadcrumb.map(asset => {
      if (asset.id == assetId) {
        selectedAsset = asset;
        let route = `/investor/${this.params['investorId']}/series/${this.queryParamsSeriesId}/holding/${this.queryParamsHoldingId}/asset/${selectedAsset.id}`;
        this.router.navigate([route], {
          relativeTo: this.activeRoute,
          queryParamsHandling: 'merge'
        });
        this.queryAssetMasterId = selectedAsset.id;
        this.assetChanged();
        this.getAssetDashboardDetails();
        return;
      }
    });
  }

  async assetChanged() {
    this.assetName = this.breadcrumbDataService.getAssetName(this.assetsDataForBreadcrumb, this.queryAssetMasterId)
    this.fetchCommonAssets();
  }

  updateAsOfDate(date) {
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, this.queryParamsHoldingId, this.queryAssetMasterId, date).then((data: AssetDashboard) => {
      this.assetData = data?.summary
      this.valReportingDate = date;
      if (this.isAssetDataUnavailable) {
        this.getBreadcrumbDropdownData(this.queryParamsInvestorId)
      }
      this.router.navigate(
        [], {
        relativeTo: this.activeRoute,
        queryParams: { reportingdate: date },
        queryParamsHandling: 'merge'
      });
      this.loadingService.setLoading(false);
    });
  }

  getSnapshot() {
    this.activityTracking.downloadSnapshot(this.queryAssetMasterId, this.assetName);
    const formattedDate = this.dateFormat.transform(this.valReportingDate, 'yyyy-MM-dd');
    let fileName = 'AssetSnapshot_' + this.assetName.replace(/\s/g, "_") + '_' + formattedDate + '.pdf'
    this.snapService.getSnapshot(this.queryParamsSeriesId, formattedDate, fileName, this.queryParamsHoldingId, this.queryAssetMasterId)
  }

  breadcrumbClick(breadcrumb) {
    this.activityTracking.navigateToDashboard(breadcrumb.type === 'Dashboard' ? 'Investor' : breadcrumb.type, breadcrumb, 'asset dashboard breadcrumbs');
    if (breadcrumb.name === 'Dashboard') {
      this.investorRepository.setActiveEntity(breadcrumb.id);
    }
  }
}
